import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import img_Record from '../../assets/img/NUG-3xl04-record.jpg';
import img_Product from '../../assets/img/NUG-3xl04-product.jpg';

function XXXL04() {

  const headerProps = {
    title: 'NUG - Napping Under God',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />

      <main>
        <article className='works'>
          <div>
            <p className='text'> 3XL04, 10-2022 </p>
            <p className='text'> duo with Florian T M Zeisig </p>

            <div>
            <a className='landing-link' href='https://bblisss.bandcamp.com/album/napping-under-god' target='_blank' rel='noopener noreferrer'>
                bandcamp
            </a>
            </div>

            <div>
            <a className='landing-link' href='https://boomkat.com/products/napping-under-god' target='_blank' rel='noopener noreferrer'>
                boomkat
            </a>
            </div>

            <div>
            <a className='landing-link' href='https://inverted-audio.com/napping-under-god-3xl/' target='_blank' rel='noopener noreferrer'>
                I/A announcement
            </a>
            </div>

            <img className='works-img-med' alt='Napping Under God cover' src={img_Record}></img>
            <img className='works-img-med' alt='Napping Under God product' src={img_Product}></img>
          </div>
        </article>
      </main>

      <Footer hidden='hidden' position='footer-position' />

    </>
  );
}

export default XXXL04;
