import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import img_Installation from '../../assets/img/FocalField-static.png'
import img_Tracking from '../../assets/img/FocalField-tracking.jpg'
import img_Building from '../../assets/img/FocalField-building.jpg'

function FocalField() {

  const headerProps = {
    title: 'Focal Field',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />

      <main>
        <article className='works'>
          <div>
            <p className='text'> Focal Field explores the spectrum of presence felt in augmented sonic reality – from the intimacy of closeness to the abstracted relation between disembodied voice, sound, and space. Perceptual relations between the real and virtual are tightened by situating augmented reality sound within synthetic reverberant architectures, and reinforcing localisation through light. The installation engages with our necessity to rationalise any aural manifestation. These fields of fragmentation – between the spectrums of connection and disconnection – have led the duo to draft a sound space where the voice, the Phonè, and its experience become the object and subject of aesthetic speculation. </p>
            <br/>

            <p className='text'> Focal Field was created in collaboration with Davide Luciani as Artists in Residence 2021 at the Institute for Computer Music and Sound Technology at ZhdK in Zurich, CH. </p>
            <br/>

            <div>
            <a className='landing-link' href='https://www.zhdk.ch/en/event/45760' target='_blank' rel='noopener noreferrer'>
                ICST / ZhdK announcement
            </a>
            </div>
            
            <br/>

            <img className='works-img-large' alt='Focal Field documentation' src={img_Installation}></img>
            <img className='works-img-small' alt='Focal Field documentation' src={img_Tracking}></img>
            <img className='works-img-small' alt='Focal Field documentation' src={img_Building}></img>
            <p className='works-img-large'>
              <div style={{padding:"56.25% 0 0 0", position:'relative'}}>
                <iframe src="https://player.vimeo.com/video/850839075?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}} title="Focal Field"></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </p>

          </div>
        </article>
      </main>

      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default FocalField;
