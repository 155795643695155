import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

function Press() {

  const headerProps = {
    title: 'press',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />
      <main>
        <article className='press'>
            
            <br/>

            <p className='text'> 2024 </p>

            <a className='landing-link' href='https://futurismrestated.substack.com/p/futurism-restated-58-nuggets-bagpipes' target='_blank' rel='noopener noreferrer'>
                Bong Boat - Futurism Restated: Record of the Week
            </a>
            <a className='landing-link' href='https://daily.bandcamp.com/best-ambient/the-best-ambient-music-on-bandcamp-april-2024' target='_blank' rel='noopener noreferrer'>
                Bong Boat - Bandcamp Daily: Best Ambient April '24
            </a>

            <br/>

            <p className='text'> 2023 </p>

            <a className='landing-link' href='https://inverted-audio.com/review/pvas-somaesthesia/' target='_blank' rel='noopener noreferrer'>
                Somaesthesia - I/A Review
            </a>
            <a className='landing-link' href='https://ra.co/reviews/35389' target='_blank' rel='noopener noreferrer'>
                Somaesthesia - Resident Advisor Review
            </a>
            <a className='landing-link' href='https://www.juno.co.uk/charts/juno-recommends/1129184-Juno_Recommends_Bass/8567262-Chart/' target='_blank' rel='noopener noreferrer'>
                Somaesthesia - Juno Recommends: Best Bass  2023
            </a>

            <br/>

            <p className='text'> 2022 </p>

            <a className='landing-link' href='https://inverted-audio.com/feature/best-albums-of-2022/' target='_blank' rel='noopener noreferrer'>
                Napping Under God - I/A Top 10 Albums of 2022
            </a>
            <a className='landing-link' href='https://www.phonicarecords.com/best-of-list/best-of-2022-albums/184' target='_blank' rel='noopener noreferrer'>
                Napping Under God - Phonica Records Best Albums of 2022
            </a>
        </article>
      </main>
      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default Press;
