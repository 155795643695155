import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import img_Documentation1 from '../../assets/img/VFYFP-documentation.jpg';
import img_Documentation2 from '../../assets/img/VFYFP-exhibition-img1.jpg';
import img_Documentation3 from '../../assets/img/VFYFP-exhibition-img2.jpg';

function VFYFP() {

  const headerProps = {
    title: 'Voices From Your Future Past',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>
      <Header title={headerProps.title} style={headerProps.style} />
      <main>
        <article className='works'>
          <div>
            <p className='text'> Voices From Your Future Past is a site-specific, city-sized augmented reality audio installation. It was conceived in collaboration with sound artists Davide Luciani and Fabio Perletta, and Norwegian singer Stine Janvin; it was curated by Daniela Arriado, and produced by Art Republic and Mote Studio. The work was presented by Son.AR. </p>
            <br/>

            <p className='text'> The installation was presented during the Ultima Festival for Contemporary Music, in different areas across the city of Oslo: in the proximity to Blå, Atelier Nord, Sentralen, Oslo Kunstforening and the Oslo Opera House. The audience was invited to take a journey through these locations, seeking out sites of aural experience. </p>
            <br/>

            <p className='text'> The artwork could be experienced individually at each location or as a sound walk. The spatialised audio dramaturgy interacted with the listener's position in space to produce an interactive augmented reality sound stage. </p>
            <br/>

            <div>
            <a className='landing-link' href='https://www.motestudio.net/project/voices-from-your-future-past' target='_blank' rel='noopener noreferrer'>
                website
            </a>
            </div>

            <div>
            <a className='landing-link' href='https://www.ultima.no/en/voices-from-your-future-past' target='_blank' rel='noopener noreferrer'>
                Ultima Contemporary programme
            </a>
            </div>
            
            <br/>
            
            <img className='works-img-large' alt='vfyfp documentation' src={img_Documentation1}></img>
            <img className='works-img-small' alt='vfyfp documentation' src={img_Documentation3}></img>
            <img className='works-img-small' alt='vfyfp documentation' src={img_Documentation2}></img>
          </div>
        </article>
      </main>
      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default VFYFP;
