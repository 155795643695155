import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

function Mastering() {

  const headerProps = {
    title: 'mastering',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />
      <main>
        <article>
            <a className='landing-link' href='https://www.discogs.com/artist/6676043-Jordan-Juras' target='_blank' rel='noopener noreferrer'>
              digital, cassette, and vinyl
            </a>
            <div>
            <Link className='landing-link' to='/contact'>
              inquire
            </Link>
            </div>
        </article>
      </main>
      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default Mastering;
