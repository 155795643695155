import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import img_App1 from '../../assets/img/SONAR-app-1.png';
import img_App2 from '../../assets/img/SONAR-app-2.jpg';

function SonAR() {

  const headerProps = {
    title: 'Screen City Biennial AR Navigation Guide',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />

      <main>
        <article className='works'>
          <div>
            <p className='text'> Son.AR collective's first work aimed to develop an innovative AR sonic-navigation application for mobile phones for the 2019 Screen City Biennial in Stavenger, Norway. The app combinined dynamic music compositions and GPS/compass sensor data to create augmented reality sound spaces to draw attendees to the various exhibition venues. </p>
            <br/>

            <p className='text'> Visitors could locate the Biennial's venues by using binaural localisation to determine their orientation and distance from these virtual sound stages. </p>
            <br/>

            <p className='text'> The sound experience was composed of electronic music, acousmatic sounds, spoken word, and cybernetic voices. </p>
            <br/>

            <div>
            <a className='landing-link' href='https://2019.screencitybiennial.org/sonar' target='_blank' rel='noopener noreferrer'>
                website
            </a>
            </div>

            <br/>

            <img className='works-img-small' alt='son-ar app 1' src={img_App1}></img>
            <img className='works-img-small' alt='son-ar app 2' src={img_App2}></img>
          </div>
        </article>
      </main>
      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default SonAR;
