import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import img_Record from '../../assets/img/AOC-SliceSplit-cover.jpeg';

function XXXLSliceSplit() {

  const headerProps = {
    title: 'avaliable on computers / Dylan Kerr - Slice Split',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />

      <main>
        <article className='works'>
          <div>
            <p className='text'> 3XL Digi, 02-2024 </p>
            <p className='text'> split release with Dylan Kerr </p>

            <div>
            <a className='landing-link' href='https://bblisss.bandcamp.com/album/slice-split' target='_blank' rel='noopener noreferrer'>
                bandcamp
            </a>
            </div>

            <img className='works-img-med' alt='Slice Split cover' src={img_Record}></img>
          </div>
        </article>
      </main>

      <Footer hidden='hidden' position='footer-position' />

    </>
  );
}

export default XXXLSliceSplit;
