import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

function Contact() {

  const headerProps = {
    title: 'contact',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />
      <main>
        <article>
            <p className='text'> jordan dot juras [at] gmail dot com </p>
            <a className='landing-link' href='https://www.instagram.com/joju_hd' target='_blank' rel='noopener noreferrer'>
                instagram
            </a>
        </article>
      </main>
      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default Contact;
