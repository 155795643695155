import React from 'react';
import { Link } from 'react-router-dom';

import Scroll from './Scroll';

interface IProps {
  hidden: string;
  position: string;
}

function Footer(props: IProps) {
  return (
    <footer className={`footer ${props.position}`}>
      <Scroll hidden={props.hidden} />
      <Link className='footer-text' to='/'>
        <small> &copy; Jordan Juras 2024 </small>
      </Link>
    </footer>
  );
}

export default Footer;
