import React from 'react';

interface IProps {
  hidden: string;
}

const Scroll = (props: IProps) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return <i title='back to top' onClick={handleClick} className={`fas fa-long-arrow-alt-up arrow-icon ${props.hidden}`}></i>;
};

export default Scroll;
