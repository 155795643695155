import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { ImmersiveWorks, Discography, Software } from '../components/Lists';

function Landing() {
  return (
    <>
      <Header title='Jordan Juras' style='header-landing' />

      <main className='landing'>

        <p>
            <Link className='landing-link' to='/bio'>
              bio
            </Link>
        </p>

        <h2 className='landing-text__title'>Immersive Works</h2>
        <ImmersiveWorks />

        <h2 className='landing-text__title'>Discography</h2>
        <Discography />

        <p>
            <Link className='landing-link' to='/VVC'>
              viz-vibe-curator
            </Link>
        </p>

        <p>
            <Link className='landing-link' to='/soundprint-archive'>
              soundprint archive
            </Link>
        </p>

        <p>
            <Link className='landing-link' to='/publications'>
              publications
            </Link>
        </p>

        <p>
            <Link className='landing-link' to='/press'>
              press
            </Link>
        </p>

        <p>
            <Link className='landing-link' to='/mastering'>
              mastering
            </Link>
        </p>


        <br/>
        <br/>

        <p>
            <Link className='landing-link' to='/contact'>
              contact
            </Link>
        </p>

      </main>

      <Footer hidden='hidden' position='footer-position' />

    </>
  );
}

export default Landing;
