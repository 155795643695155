import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

import img_Boblo from '../assets/img/SoundprintArchive-boblo.jpg'
import img_Packard1 from '../assets/img/SoundprintArchive-packardPlant.jpg'
import img_Packard2 from '../assets/img/SoundprintArchive-packardBalloons.jpg'
import img_Wide from '../assets/img/SoundprintArchive-unknown.jpg'

function SoundprintArchive() {

  const headerProps = {
    title: 'soundprint archive',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />
      
      <main>
        <article className='works'>
          <div>
            <p className='text'> soundprint archive is a collection of percussive, field-recorded audio samples that are geographically sorted through an interactive map. Musicians can select and use sets of sounds recorded in the same location that feature coherent resonant and reverb characteristics. Impulse responses recorded at each location assist musicians and sound artists to create a cohesive soundstage that includes unrelated audio content. </p>
            <p className='text'> soundprint archive was created in 2013 with funding from the Ontario Arts Council, and support from many collaborators including: Chrys Vilvang, Kendall Stephenson, Danji Buckmore, and Max Stein. </p>

            <br/>

            

            <div>
            <a className='landing-link' href='https://soundprintarchive.com' target='_blank' rel='noopener noreferrer'>
                website
            </a>
            </div>

            <div>
            <a className='landing-link' href='https://soundprintarchive.bandcamp.com' target='_blank' rel='noopener noreferrer'>
                bandcamp
            </a>
            </div>

            <a className='landing-link' href='https://espenrasmussen.com/HARD-LAND/DETROIT/2/caption' target='_blank' rel='noopener noreferrer'>
              Espen Rasmussen documentation,  
            </a>
            <a className='landing-link' href='https://www.instagram.com/p/BiWYgdJH1_R/' target='_blank' rel='noopener noreferrer'>
              &nbsp;World Press Photo IG
            </a>
            
            <br/>
            <br/>

            <img className='works-img-small' alt='packard plant' src={img_Packard1}></img>
            <img className='works-img-small' alt='packard balloons' src={img_Packard2}></img>
            <img className='works-img-large' alt='unknown building' src={img_Wide}></img>
            <img className='works-img-large' alt='boblo dancehall' src={img_Boblo}></img>

            <p className='works-img-large'>
              <div style={{padding:"56.25% 0 0 0", position:'relative'}}>
                <iframe src="https://player.vimeo.com/video/85801474?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}} title="soundprint archive: Boblo Island Dance Pavilion - IBeams"></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </p>

            <p className='works-img-large'>
              <div style={{padding:"56.25% 0 0 0", position:'relative'}}>
                <iframe src="https://player.vimeo.com/video/85801475?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}} title="soundprint archive: Boblo Island Dance Pavilion - Wooden"></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </p>

          </div>
        </article>
      </main>
      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default SoundprintArchive;
