import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import img_Record from '../../assets/img/NUG-OUEST085-cover.jpeg';
import img_Product from '../../assets/img/NUG-OUEST085-product.jpeg';

function OUEST085() {

  const headerProps = {
    title: 'NUG - Bong Boat',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />

      <main>
        <article className='works'>
          <div>
            <p className='text'> OUEST085, 04-2024 </p>
            <p className='text'> duo with Florian T M Zeisig </p>

            <div>
            <a className='landing-link' href='https://westmineral.bandcamp.com/album/bong-boat-ouest085' target='_blank' rel='noopener noreferrer'>
                bandcamp
            </a>
            </div>

            <div>
            <a className='landing-link' href='https://boomkat.com/products/bongboat' target='_blank' rel='noopener noreferrer'>
                boomkat
            </a>
            </div>

            <div>
            <a className='landing-link' href='https://firstfloor.substack.com/p/first-floor-211-at-least-people-are' target='_blank' rel='noopener noreferrer'>
                First Floor announcement
            </a>
            </div>
            
            <div/>

            <div>
            <a className='landing-link' href='https://www.instagram.com/marutonkun/' target='_blank' rel='noopener noreferrer'>
                artwork by marutonkun
            </a>
            </div>

            <img className='works-img-med' alt='Bong Boat cover' src={img_Record}></img>
            <img className='works-img-med' alt='Bong Boat product' src={img_Product}></img>
          </div>
        </article>
      </main>

      <Footer hidden='hidden' position='footer-position' />

    </>
  );
}

export default OUEST085;
