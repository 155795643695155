import React from 'react';

import { 
  BrowserRouter, 
  Routes, 
  Route 
} from 'react-router-dom';

import Landing from './pages/Landing';
import Bio from './pages/Bio';
import Contact from './pages/Contact';
import Press from './pages/Press';
import Mastering from './pages/Mastering';
import VVC from './pages/VVC';
import SoundprintArchive from './pages/SoundprintArchive';
import Publications from './pages/Publications';

import Otherspace from './pages/immersive/Otherspace';
import FocalField from './pages/immersive/FocalField';
import VFYFP from './pages/immersive/VFYFP';
import SonAR from './pages/immersive/SonAR';

import OUEST085 from './pages/releases/OUEST085';
import XXXLSliceSplit from './pages/releases/XXXLSliceSplit';
import ISLA33 from './pages/releases/ISLA33';
import XXXL04 from './pages/releases/XXXL04';
import XPQ66 from './pages/releases/XPQ66';
import STRAY003 from './pages/releases/STRAY003';

// unlisted pages
import OtherspaceBetaIOSPrivacyPolicy from './pages/unlisted/otherspace/betaIOSPrivacyPolicy';
import OtherspaceBetaAndroidPrivacyPolicy from './pages/unlisted/otherspace/betaAndroidPrivacyPolicy';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Landing />} />

        <Route path='/bio' element={<Bio />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/press' element={<Press />} />
        <Route path='/mastering' element={<Mastering />} />
        <Route path='/vvc' element={<VVC />} />
        <Route path='/soundprint-archive' element={<SoundprintArchive />} />
        <Route path='/publications' element={<Publications />} />

        <Route path='/otherspace' element={<Otherspace />} />
        <Route path='/focal-field' element={<FocalField />} />
        <Route path='/vfyfp' element={<VFYFP />} />
        <Route path='/son-ar' element={<SonAR />} />

        <Route path='/ouest085' element={<OUEST085 />} />
        <Route path='/xxxl-slice-split' element={<XXXLSliceSplit />} />
        <Route path='/isla33' element={<ISLA33 />} />
        <Route path='/xxxl04' element={<XXXL04 />} />
        <Route path='/xpq66' element={<XPQ66 />} />
        <Route path='/stray003' element={<STRAY003 />} />

        {/* unlisted pages */}
        <Route path='/otherspace_beta_privacypolicy_ios' element={<OtherspaceBetaIOSPrivacyPolicy />} />
        <Route path='/otherspace_beta_privacypolicy_android' element={<OtherspaceBetaAndroidPrivacyPolicy />} />
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;

