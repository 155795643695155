import React from 'react';
import { Link } from 'react-router-dom';

export function ImmersiveWorks() {
  return (
    <nav>
      <ul className='landing-list'>
        <li>
          <Link className='landing-link' to='/otherspace'>
            otherspace
          </Link>
        </li>
        <li>
          <Link className='landing-link' to='/focal-field'>
            Focal Field
          </Link>
        </li>
        <li>
          <Link className='landing-link' to='/vfyfp'>
            Voices From Your Future Past
          </Link>
        </li>
        <li>
          <Link className='landing-link' to='/son-ar'>
            SCB2019 AR Navigation Guide
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export function Discography() {
  return (
    <nav>
      <ul className='landing-list'>
      <li>
          <Link className='landing-link' to='/ouest085'>
            NUG - Bong Boat
          </Link>
      </li>
      <li>
          <Link className='landing-link' to='/xxxl-slice-split'>
            available on computers / Dylan Kerr - slice split
          </Link>
      </li>
      <li>
          <Link className='landing-link' to='/isla33'>
            PVAS - Somaesthesia
          </Link>
      </li>
      <li>
          <Link className='landing-link' to='/xxxl04'>
            NUG - Napping Under God
          </Link>
        </li>
        <li>
          <Link className='landing-link' to='/xpq66'>
            PVAS - Accepting eEntropy Loss
          </Link>
        </li>
        <li>
          <Link className='landing-link' to='/stray003'>
            Valium - Ziegra 1
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export function Software() {
  return (
    <nav>
      <ul className='landing-list'>
        <li>
          <Link className='landing-link' to='/vvc'>
            viz-vibe-curator
          </Link>
        </li>
        <li>
          <Link className='landing-link' to='/umt'>
            UMT
          </Link>
        </li>
      </ul>
    </nav>
  );
}

