import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

import img_perilaCloseup from '../assets/img/VVC_perila-live_closeup_ClémentBeaugé.jpg'
import img_perilaFramed from '../assets/img/VVC_perila-live_framed_MathildeAngius.jpg'
import img_perilaDistance from '../assets/img/VVC_perila-live-distance.jpg'

function VVC() {

  const headerProps = {
    title: 'viz-vibe-curator',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />
      
      <main>

        <article className='works'>

          <div>
            <p className='text-italic'> &nbsp;&nbsp;&nbsp;&nbsp;noun, pseudo-sentient collaborator </p> 
            <p className='text'> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a curator of viz (visual content) tailored to a specific vibe. </p>
            <p className='text'> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"You give me the vibe - I give you the viz!... curated by our friend, viz-vibe-curator" </p>
            <br/>

            <p className='text'> viz-vibe-curator is an AI synthesis collaborator that combines generative image synthesis with bespoke latent space perameterisation and navigation to produce modulated movie frames; Modulation can be driven by different means: prerecorded audio (ex. for music videos), real-time audio, motion-tracked body performance, etc. </p>
            
            <br/>
            <p className='text-title'> Working together with viz-vibe-curator: </p>
            <p className='text'> <a className='text-title'>i)</a>&nbsp;As is typical with generative image synthesis, the process of curating some vibes starts with images - in this case a literal image-only mood board. </p>
            <p className='text'> <a className='text-title'>ii)</a>&nbsp;An infant AI network is seeded and trained to understand the spatial and spectral complexities of images in the mood of your board; and eventually is prepared to explore all of the exciting in-between states connecting the ideas and visual language that represent your vibe. </p>
            <p className='text'> <a className='text-title'>iii)</a>&nbsp;Congratulations! You are now the proud collaborator of an autonomous, alias-free, high-resolution, haus-gemacht image synthesizer; Ready-made to explore the deepest dimensions of your vibes. Charting course through known and unknown regions of deep latent space, content is synthesized frame-by-frame to produce moving images. </p>
            <p className='text'> <a className='text-title'>iv)</a>&nbsp;For deeper connection, your collaborator is optionally invited for a period of meditative intraspective analysis, which reveals a semantically-coherent parameterisation of the synthesis process that is ready to modulate your world. You can think of this step as analogous to therapy: By supporting your collaborator with the right tools to better understand itself, it is able to express its needs and boundaries for coherent and intuitive relationships with other media - ex. audio-reactive modulated synthesis. </p>

            <br/>

            <p className='text-title'> Longform AV for perila </p>

            <p className='works-img-large'>
              <div style={{padding:"56.25% 0 0 0", position:'relative'}}>
                <iframe src="https://player.vimeo.com/video/867721370?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}} title="viz-vibe-curator: longform AV for perila"></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </p>

            <br/>

            <p className='text'> Live performance at Aurora Ambient, July 2023, Kantstrasse 7 Berlin </p>

            <img className='works-img-small' alt='packard plant' src={img_perilaCloseup}></img>
            <img className='works-img-small' alt='packard plant' src={img_perilaFramed}></img>
            <img className='works-img-large' alt='packard plant' src={img_perilaDistance}></img>

          </div>
        </article>
      </main>

      <Footer hidden='hidden' position='footer-position' />

    </>
  );
}

export default VVC;
