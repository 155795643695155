import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import img_Lobe from '../../assets/img/otherspace-lobe-img.jpg';
import img_Promo from '../../assets/img/otherspace-promo-img.jpg';
import img_Mock from '../../assets/img/otherspace-mock.jpg';
import img_Logo from '../../assets/img/otherspace-logo.png';

import img_CCALogo from '../../assets/img/logo-CCA.jpg';
import img_MusikfondsLogo from '../../assets/img/logo-Musikfonds.jpg';
import img_LobeLogo from '../../assets/img/logo-Lobe.png';
import img_MonomLogo from '../../assets/img/logo-Monom.png';

function Otherspace() {

  const headerProps = {
    title: 'otherspace',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>
      
      <Header title={headerProps.title} style={headerProps.style} />

      <main>
        <article className='works'>
          <div>
            <p className='text'> Created in collaboration with sound artist perila, and designer Kristen Stokes, otherspace is a generative, audio-reactive, and immersive album composed in a smartphone app. </p>
            <br/>
            <p className='text'> otherspace creates a unique sound experience each time it is used. It records a listener’s sound environment, and plays back elements of the soundscape within four different atmospheric spaces composed by perila (analogous to songs in the conventional album format). The generative playback and spatialisation of perila’s spaces are driven by a real-time analysis of the listener’s sound environment.</p>
            <br/>
            <p className='text'> As a portable sound work for smartphones, otherspace proposes a new perspective on musical performance; wherein the listener is invited as a collaborator and mediates a musical outcome as they navigate throughout their sonic landscape. This playful and immersive sound experience intends to compel listeners to forge new positive relationships with the soundscapes that feature in everyday life. </p>
            <br/>
            <p className='text'> Two performances developed by perila and Juras reinterpret the work for the ‘4DSOUND’ spatial audio sound systems at Lobe Studio in Vancouver (May 2023), and Monom in Berlin (forthcoming). </p>
            <br/>

            <div>
            <a className='landing-link' href='https://otherspace.cc' target='_blank' rel='noopener noreferrer'>
                website
            </a>
            </div>
            <div>
            <a className='landing-link' href='https://lobestudio.ca/jordan-and-perila' target='_blank' rel='noopener noreferrer'>
              Lobe residency, 
            </a>
            <a className='landing-link' href='https://lobestudio.ca/new-events/lobe-artist-residency-series-jordan-juras-and-perila' target='_blank' rel='noopener noreferrer'>
                &nbsp;performance
            </a>
            
            </div>
            <br/>

            <img className='works-img-med' alt='otherspace promo img' src={img_Promo}></img>
            <img className='works-img-med' alt='otherspace lobe residency' src={img_Lobe}></img>
            <img className='works-img-small' alt='otherspace mock' src={img_Mock}></img>
            <img className='works-img-small' alt='otherspace logo text' src={img_Logo}></img>

            <br/>
            <br/>
                        
            <img className='logo-small' alt='cca logo' src={img_CCALogo}></img>
            <img className='logo-large' alt='musikfonds logo' src={img_MusikfondsLogo}></img>
            <img className='logo-large' alt='lobe logo' src={img_LobeLogo}></img>
            <img className='logo-small' alt='monom logo' src={img_MonomLogo}></img>

          </div>
        </article>
      </main>

      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default Otherspace;
