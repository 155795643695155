import React from 'react';

interface IProps {
  title: string;
  style: string;
}

function Header(props: IProps) {
  return (
    <header>
      <h1 className={`header ${props.style}`}>{props.title}</h1>
    </header>
  );
}

export default Header;
