import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import img_Front from '../../assets/img/PVAS-isla33-front.jpg';
import img_Back from '../../assets/img/PVAS-isla33-back.jpg';

function ISLA33() {

  const headerProps = {
    title: 'PVAS - Somaesthesia',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />
      <main>
        <article className='works'>
         <div>

          <p className='text'> xpq?66, 06-2021 </p>
            
          <div>
          <a className='landing-link' href='https://isla.bandcamp.com/album/somaesthesia' target='_blank' rel='noopener noreferrer'>
              bandcamp
          </a>
          </div>

          <div>
            <a className='landing-link' href='https://www.one-eye-witness.com/release/1674493658/PVAS-Somaesthesia' target='_blank' rel='noopener noreferrer'>
                one eye witness
            </a>
          </div>

          <div>
          <a className='landing-link' href='https://www.instagram.com/data.bitch/' target='_blank' rel='noopener noreferrer'>
              artwork by Dre Roelandt
          </a>
          </div>

          <img className='works-img-small' alt='somaesthesia front' src={img_Front}></img>
          <img className='works-img-small' alt='somaesthesia back' src={img_Back}></img>

          </div>
        </article>
      </main>
      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default ISLA33;
