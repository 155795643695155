import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import img_Bio from '../assets/img/bio-pic.jpg';

function Bio() {

  const headerProps = {
    title: 'bio',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />

      <main>
        <article className='bio'>
          <div>
            <p className='text'> Jordan Juras is a Canadian immersive artist, music producer, audio researcher and consultant based in berlin. He releases music under the aliases PVAS, Available on Computers, NUG (together with Florian T M Zeisig) and marsh (together with Dylan Kerr). He completed a Bachelors of Science in Physics at McGill University (2014), and a Masters of Music in Music Technology at New York University (2016) where he conducted research in personalised spatial audio and music information retrieval under Drs. Agnieszka Roginska and Juan Bello. </p>
            <br/>
            <p className='text'> With experience gained working as a Research Engineer in the music technology industry, he began to develop immersive experiences in 2019 with a practice that explores subconscious or invisible externalities and challenges the behavioral responses that shape our experience. </p>
            <br/>
            <p className='text'> He has created immersive works for the Ultima Contemporary Music Festival (Oslo, 2021), the Institute for Computer Music and Sound Technology at ZhdK (Zurich, 2021), and the Screen City Biennale (Stavanger, 2019). </p>
          </div>
          <div>
            <img className='bio-img' alt='Jordan Juras img' src={img_Bio}></img>
          </div>
        </article>
      </main>

      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default Bio;
