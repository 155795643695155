import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import img_Record from '../../assets/img/Valium-stray003-record.jpg';

function STRAY003() {

  const headerProps = {
    title: 'Valium - Ziegra 1',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />
      <main>
        <article className='works'>
        <div>

          <p className='text'> STRAY003, 03-2020 </p>
          <p className='text'> duo with Alfred Brooks </p>

          <div>
          <a className='landing-link' href='https://straysignals.bandcamp.com/album/ziegra-1' target='_blank' rel='noopener noreferrer'>
              bandcamp
          </a>
          </div>
          
          <img className='works-img-med' alt='Somaesthesia' src={img_Record}></img>
          </div>
        </article>
      </main>
      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default STRAY003;
