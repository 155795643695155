import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

function Publications() {

  const headerProps = {
    title: 'publications',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>

      <Header title={headerProps.title} style={headerProps.style} />
      <main>
        <article className='publications'>
            
            <br/>

            <p className='text-title'> 2022 </p>

            <p className='publications-citation'>
            <code > Ramires, A., Juras, J., Parker, J.D., Serra, X. “A Study of Control Methods for Percussive Sound Synthesis Based on GANs.” Presented at the International Conference on Digital Audio Effects, Vienna, Austria (2022).</code>
            <br/>
            <a className='link' href='https://youtu.be/yuc7j7-U0aQ' target='_blank' rel='noopener noreferrer'>
                DaFX22 presentation
            </a>
            </p>            


            <p className='text-title'> 2016 </p>

            <p className='publications-citation'>
            <code > Genovese, A., Juras, J., Miller, C., Roginska, A. “Investigation on ITD Symmetry Across Existing Databases of Personalized HRTFs.” The 22nd International Conference on Auditory Display, Canberra, Australia (2016).</code>
            </p>

            <p className='publications-citation'>
            <code > Miller, C., Juras, J., Genovese, A., Roginska, A. “Interaural Distances in Existing HRIR Repositories.” Audio Engineering Society Conference on Headphone Technology, Aalborg, Denmark (2016). </code>
            </p>

            <p className='publications-citation'>
            <code > Genovese, A., Juras, J., Miller, C., Roginska, A. “The Effect of Elevation on ITD Symmetry.” Audio Engineering Society Conference on Headphone Technology, Aalborg, Denmark (2016). </code>
            </p>

            <p className='text-title'> 2015 </p>

            <p className='publications-citation'>
            <code > Juras, J., Miller, C., Roginska, A. (October, 2015). “Modeling ITDs Based on Photographic Head Information.” Presented at the Audio Engineering Society Convention 139, Spatial Audio – Part 2. New York, USA.</code>
            </p>

        </article>
      </main>
      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default Publications;